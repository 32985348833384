import './App.css';
import React, { useEffect, useState, useRef } from "react";

import { ZoomMtg } from '@zoomus/websdk';

import { Stack, 
  Box,
  Typography, 
  Button, 
  CardContent, 
  IconButton, 
  Tooltip, 
  Avatar,
  LinearProgress
 } from '@mui/material';

import Robot404 from './assets/robot-404.png' 
import RobotOffline from './assets/robot-offline.png' 
import RobotConnect from './assets/robot-connect.png' 
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
// Soft UI Dashboard React components
import {apiPostUnsecure, signatureApi, controlTemiApi} from './utils/api';
import { ShowToast } from './utils/diloag';

export function useQueryParams() {
  const params = new URLSearchParams(
    window ? window.location.search : {}
  );

  return new Proxy(params, {
      get(target, prop) {
          return target.get(prop)
      },
  });
}

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.9.7/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

const sdkKey = 'db5fy07UbrYhRAUGEu5OUlRJIvzDOCjmwnM0'
const role = 0
const leaveUrl = 'https://robot.zconnect.tech/thankyou'
const userEmail = ''
const registrantToken = ''

function App() {

  const [showToastSuccess, setShowToastSuccess] = useState()
  const [showToastError, setShowToastError] = useState()
  const [init, setInit] = useState(true)
  const [showActions, setShowActions] = useState(false)
  const [progress, setProgress] = useState(true)
  const toastMessage = useRef(null)
  const meeting = useRef(null)
  const errorMessage = useRef(null)

  const { m, p } = useQueryParams();


  useEffect(() => {
    setInit(false);
    if(m && p) {
      getSignature()
    }else {
      errorMessage.current = "Invalid Link!"
      setProgress(false)
    }
  }, [init])

  console.log('id', m);
  console.log('pwd', p);

  const controlTemi = (mId, mAction) => {
    
    apiPostUnsecure(
      controlTemiApi,
      {
        id: mId, 
        action: mAction, 
      },
      (response) => {
        toastMessage.current = response.msg;
        setShowToastSuccess(true)
        console.log('response', response)
      },
      (error) => {
        toastMessage.current = error;
        setShowToastError(true)
        console.log('error', error)
      }
    )
  
  }

  const tiltUp = () => {
    controlTemi(m, 'UP')
  }

  const tiltDown = () => {
    controlTemi(m, 'DOWN')
  }

  const rotateLeft = () => {
    controlTemi(m, 'LEFT')
  }

  const rotateRight = () => {
    controlTemi(m, 'RIGHT')
  }

  const getSignature = () => {

    apiPostUnsecure(
      signatureApi,
      {
        meetingNumber: m,
        role: role,
        password: p,
      },
      (response) => {
        setProgress(false)
        console.log('response', response)
        meeting.current = response.meeting
        startMeeting(response.signature)
      },
      (error) => {
        setProgress(false)
        errorMessage.current = error
        console.log('error', error)
      }
    )

  }
  
  const startMeeting = (signature) => {
    const doc = document.getElementById('zmmtg-root')
    doc.style.display = 'block'

    console.log('doc', doc);
    console.log('signature', signature);
    console.log('passWord', p);
    console.log('meetingNumber', m);
    console.log('userName', meeting.current?.family_name);
    console.log('userEmail', userEmail);
    console.log('sdkKey', sdkKey);
    console.log('tk', registrantToken);

    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        console.log('success', success)
        setShowActions(true)
        ZoomMtg.join({
          signature: signature,
          meetingNumber: m,
          userName: meeting.current?.family_name,
          sdkKey: sdkKey,
          userEmail: userEmail,
          passWord: p,
          tk: registrantToken,
          success: (success) => {
            console.log('success', success)
          },
          error: (error) => {
            console.log('error', error)
          }
        })
      },
      error: (error) => {
        console.log('error', error)
      }
    })
  }

  const handleCloseSuccess = (event, reason) => {
    
    if (reason === 'clickaway') {
      return;
    }
    setShowToastSuccess(false);
  };

  const handleCloseError = (event, reason) => {
    
    if (reason === 'clickaway') {
      return;
    }
    setShowToastError(false);
  };

  const GetActions = () => {

    if(showActions) {
        return (
          <CardContent >
          <Typography variant="h6" sx={{p:1, color: '#888'}}>Adjust Temi</Typography>
          <Stack direction='row' spacing={2}>
            <Tooltip title="Tilt Up" sx={{mx: 1}}>
                <Avatar sx={{ bgcolor: '#31BAFF' }}>
                  <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => tiltUp()}>
                    <KeyboardArrowUpIcon />
                  </IconButton>
                </Avatar>        
              </Tooltip>
              <Tooltip title="Tilt Down" sx={{mx: 1}}>
                <Avatar sx={{ bgcolor: '#31BAFF' }}>
                  <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => tiltDown()}>
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </Avatar>        
              </Tooltip>
              {
              /* <Tooltip title="Rotate Left" sx={{mx: 1}}>
                <Avatar sx={{ bgcolor: '#31BAFF' }}>
                  <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => rotateLeft()}>
                    <KeyboardArrowLeftIcon />
                  </IconButton>
                </Avatar>        
              </Tooltip>
              <Tooltip title="Rotate Right" sx={{mx: 1}}>
                <Avatar sx={{ bgcolor: '#31BAFF' }}>
                  <IconButton sx={{ color: '#fff' }}  variant="contained" size="small" onClick={() => rotateRight()}>
                    <KeyboardArrowRightIcon />
                  </IconButton>
                </Avatar>        
              </Tooltip> */
              }
          </Stack>
        </CardContent>
        )
    }
    return null
  }


  const Content = () => {

    if(progress) {
      return (
        <Box 
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            sx={{ 
              width: '100%', 
              height: '100%'}}
          >
              <Stack>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{width:'100%'}}
                  >
                    <img src={RobotConnect} width='150px' />
                  </Box>
                  <Typography sx={{mt:4}} variant="h5">Please wait...</Typography>
                <Box>
                  <LinearProgress color="primary" />
                </Box>
              </Stack>
          </Box>
      )
    }

    console.log('error >> ', errorMessage.current)

    if(errorMessage.current != null) {
      return (
        <Box 
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
          sx={{ 
            width: '100%', 
            height: '100%'}}
        >
              <Stack>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{width:'100%', }}
                  >
                    <img src={RobotOffline} width='150px' />
                  </Box>
                  <Typography sx={{mt:4}} variant="h5">{errorMessage.current}</Typography>
                  <Box>
                </Box>
              </Stack>
        </Box>
      )
    }

    return (
      <Box 
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          sx={{ 
            width: '100%', 
            height: '100%'}}
        >
            <Stack>
              <Box>
                <img src={RobotConnect} width='150px' />
                <Typography sx={{mt:4}}  variant="h5">Connecting...</Typography>
              </Box>
              <Box>
                <LinearProgress color="success" />
              </Box>
            </Stack>
        </Box>
    )
  }


  return (
    <Box>
      <ShowToast show={showToastSuccess} type="success" msg={toastMessage} close={handleCloseSuccess} />
      <ShowToast show={showToastError} type="error" msg={toastMessage} close={handleCloseError} />
      <Box sx={{ width: '100%', height: '100%' }}>
          <Content />
          <Box 
            sx={{ 
              width: '400px',
              backgroundColor: '#55000000',
              borderRadius: '20px',
              marginBottom: '50px',
              position: 'fixed',
              bottom: 0,
              left: 0,
              zIndex:99,
              }}
            >
            <GetActions />
           </Box> 
      </Box>
    </Box>
  );
}

export default App;
