import axios from "axios";

// export const baseUrl = "http://127.0.0.1:8000/";
// export const baseUrl = "https://test.zconnect.tech/";
export const baseUrl = "https://robot-api.zconnect.tech/";

export const signatureApi = "zoom/signature/";
export const controlTemiApi = "zoom/control/";


export function apiCallUnsecureGet(api, response, error) {

  // console.log('apiCallSecureGet ', api);
  axios
    .get(`${baseUrl}${api}`)
    .then((res) => {
        // console.warn('result', res)
        const result = res.data;
        if(result.code === 200) {
            response(result)
        }else {
            error(result.msg)
        }
    })
    .catch((err) => {
      if (err.response) {
        error(err.response.data.msg)
      } else if (err.request) {
        // console.log(err.request);
        error(err.request)
      } else {
        // console.log('Error', err.message);
        error(`Error ${err.message}`)
      }
      // console.log(err.config);
    });

}


export function apiPostUnsecure(api, data, response, error) {

    // console.log('apiPostUnsecure ', api, 'data ', data);

    axios
    .post(`${baseUrl}${api}`, data)
    .then((res) => {
        // console.warn('result', res)

        const result = res.data;

        if(result.code === 200) {
            response(result)
        }else {
            error(result.msg)
        }

    })
    .catch((err) => {
        if (err.response) {
            error(err.response.data.msg)
          } else if (err.request) {
            // console.log(err.request);
            error(err.request)
          } else {
            // console.log('Error', err.message);
            error(`Error ${err.message}`)
          }
          // console.log(err.config);
    });
}

function apiPostUnsecureExt(api, data, response, error) {

  // console.log('apiPostUnsecure ', api, 'data ', data);

  axios
  .post(api, data)
  .then((res) => {
      console.warn('result', res)

      const result = res.data;

      if(result.code === 200) {
          response(result)
      }else {
          error(result.msg)
      }

  })
  .catch((err) => {
    console.log('Error', err);
      if (err.response) {
          error(err.response.data.msg)
        } else if (err.request) {
          console.log(err.request);
          error(err.request)
        } else {
          console.log('Error', err.message);
          error(`Error ${err.message}`)
        }
        // console.log(err.config);
  });
}


export default apiPostUnsecureExt
